import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Fade from "react-reveal/Fade"
import Roll from "react-reveal/Roll"

const ThankYouContent = () => {
    return (
        <div className="container-xxl flex-grow-1 top-gradient-container">
            <div className="row position-relative overflow-hidden align-items-md-center thank-you-helper">
                <Fade right>
                    <StaticImage className="d-none d-md-block bg-your-details-beach" src="../../images/beach1.png" alt="Beach image" />
                </Fade>
                <Roll left delay={200}>
                    <StaticImage className="d-none d-md-block bg-your-details-girl" src="../../images/frame2.png" alt="Frame" />
                </Roll>
                <StaticImage className="d-none d-md-block bg-your-details" src="../../images/water5.png" alt="Water" />
                <Fade cascade delay={700} duration={1500}>
                    <div className="col-12 mb-3 pt-5 col-md-7 pt-md-0 ps-inside  pe-inside z-index-1">
                        <h2>Thank you!</h2>
                        <p className="mb-md-4">
                            Thank you for your booking with Maliblue Yachts, we’re looking forward to welcoming you to the White Isle.
                            We will email you your confirmation shortly, don’t forget to sign up to the latest news and updates from
                            Maliblue yachts!
                        </p>
                        <Link to="/" className="button fill light-background  d-md-inline-block" type="button">
                            return to homepage
                        </Link>
                    </div>
                </Fade>
                <div className="col-12 z-index-1 d-md-none position-relative px-0">
                    <Fade right>
                        <StaticImage className="bg-your-details-beach z-index-1" src="../../images/beach1.png" alt="Beach image" />
                    </Fade>
                    <Roll left delay={200}>
                        <StaticImage className="bg-your-details-girl z-index-2" src="../../images/frame2.png" alt="Frame" />
                    </Roll>
                </div>
                <StaticImage className="bg-your-details" src="../../images/water6.png" alt="Water" />
            </div>
        </div>
    )
}
export default ThankYouContent
