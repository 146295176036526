import * as React from "react"
import ThankYouContent from "../components/booking/thankYouContent"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import Newsletter from "../components/newsletter"
import Seo from "../components/seo"

const ThankYou = () => (
    <Layout>
        <Seo title="Thank You" />
        <NavBar whiteNav />
        <ThankYouContent />
        <Newsletter />
        <Footer />
    </Layout>
)

export default ThankYou
